body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: rgb(255, 243, 226); */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2 {
  font-family: monospace;
}

p {
  font-size: medium;
}

.config {
  /* background-color: brown; */
  max-width: 40%;
  margin: auto;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.config-row {
  display: table-cell;
  vertical-align: middle;
  /* height: 15px; */
  /* border: 1px solid red; */
  margin: 0;
  padding: 0;
}

.config-row * {
  vertical-align: middle;
}

.config button {
  border: 2px solid #333;
  border-radius: 5px;
  cursor: pointer;
  background: none;
  font: bold 0.6em sans-serif;
  padding: 5px;
  transform: rotate(-10.5deg);
}

.config button:after {
  content: ' >>';
}
.config button:hover,
.config button:focus {
  background: #000;
  color: #fff;
  outline: none;
}

.config fieldset {
  border: 2px solid #ccc;
  border-radius: 8px;
}

.config input[type='number'] {
  /* width: 50px; */
  text-align: center;
  width: fit-content;
  padding: 0;
  /* margin: 0; */
  border: 1px solid #ccc;
  border-radius: 8px;
  /* margin-bottom: 4px; */
}

.config input[type='number']:focus {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  -moz-appearance: textfield; /* Hide arrows in Firefox */
}

/* Other arrows in Chrome, Safari, Edge and Opera */
.config input::-webkit-outer-spin-button,
.config input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.config input[type='range'] {
  width: 30%;
  /* padding-top: 20px; */
  padding: 0;
  margin: 0;
}

.config legend {
  color: black;
  padding: 3px 6px;
  font-weight: bold;
}

.footer {
  background-color: #ccc;
  margin: 20px 0px 0px 0px;
  padding: 5px;
}

.footer-text,
.footer-text a {
  font-size: x-small;
  text-align: left;
  color: white;
}

.header-logo {
  /* width: 50px; */
  margin: 0px 2px 0px 2px;
  max-width: 35px;
}

.panel {
  background-color: #f8f8f8;
  border: 2px solid #ccc;
  border-radius: 8px;
  box-sizing: border-box;
  font-weight: 600;
  color: dimgray;
  height: 240px;
  margin: 12px 0px 2px 0px;
  outline: none;
  overflow: auto;
  padding: 12px 20px;
  resize: none;
  width: 60%;
}

.score {
  border: 2px solid #ccc;
  border-radius: 8px;
  height: 40px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.score-value {
  /* color: red; */
  font-size: large;
}

.score-value-last {
  color: red;
}

.score-value-best {
  color: goldenrod;
}

/* .score-value:after {
  content: ' wpm';
  font-size: xx-small;
} */

.scores-panel {
  /* background-color: yellow; */
  /* border: 2px solid #ccc; */
  /* border-radius: 8px; */
  /* height: 60px; */
  /* width: 20%; */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
